import { createContext, useContext, useState } from "react";

export type HelpContextType = {
  help: HelpType | null;
  setHelp: (help: HelpType | null) => void;
};

export const HelpContext = createContext({
  help: null,
  setHelp: () => {},
} as HelpContextType);

export function HelpContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [help, setHelp] = useState<HelpType | null>(null);
  return (
    <HelpContext.Provider
      value={{
        help: help,
        setHelp(value) {
          setHelp(value);
        },
      }}
    >
      {children}
    </HelpContext.Provider>
  );
}

export function useHelpContext() {
  const context = useContext(HelpContext);
  return context;
}

export enum HelpType {
  CHEF_HOTESSE = "Vous allez avoir besoin de moi ! Ajoutez une cheffe hotesse pour mieux gérer votre personnel pendant votre événement",
  CHEF_EQUIPE = "Vous allez avoir besoin de moi ! Ajoutez un chef d'équipe pour mieux gérer votre personnel pendant votre événement",
  EQUIPE_PRESELECTION = "Nous avons pré-sélectionné votre personnel pour votre événement ! Ces recommandations sont une base générique métier, vous pouvez modifier à tout moment votre équipe selon vos besoins !",
  DEFAULT_WORKTIME = "Nous avons attribué les heures de travail selon les horaires de votre événement. Vous pouvez modifier les horaires de votre personnel individuellement selon les heures et les jours de votre événement. Vous pouvez également choisir la rotation de personnel si vous avez besoin de personnel en permanence sur votre événement.",
  HEURE_DE_PAUSE = "Souhaitez-vous pourvoir les heures de pause ? Attention cela impactera le nombre de vos effectifs.",
  WORKTIME_COPY = 'Vous avez copié des heures de travail, sélectionnez maintenant à qui appliquer ces heures de travail, et cliquez sur "coller".',
  ENGORGEMENT = "Attention, risque d’engorgement dans les files d’attente si vos invités arrivent par vague, on vous recommande de renforcer vos équipes à l’accueil", // pas si 50-100 personnes
  ATTENTION_FIN_COMMERCIALE = "Attention, Il est interdit à votre personnel de se déplacer dans le salon à des fins commerciales.",
  TEMPS_DE_PREPARATION = "Prévoyez un temps de préparation en amont (~30 min) pour que votre animateur soit opérationnel à l’heure souhaitée !",
  PARAPLUIE = "Si votre personnel est positionné à l'extérieur, prévoyez des manteaux / parapluies !",
  PROVIDED_OUTFIT = "Merci d’indiquer dans le brief à l’étape suivante quel type de tenues vous comptez fournir et si vous nous les envoyez ou si vous les donnez le jour J.",
  PRINTED_TSHIRT = "Si vous êtes sur une opération de plusieurs jours, on vous conseille de prendre un T-shirt tous les 2 jours d’événement.",
  PRINTED_ELEMENT = "Nous aurons besoin d'une image avec le logo que vous souhaitez imprimer. Vous pourrez la télécharger sur la page de récapitulatif.",
  DISTRIBUTION_BOOST = "Boostez votre ditribution et renforcez votre notoriété avec des renforts de visibilité. Pensez aux Ballons bag ou Oriflammes bag !",
  ZOOM = "Un zoom élevé risque de déformer l'affichage de l'application ou cacher l'affichage de certaines informations. Nous vous conseillons de le diminuer.",
  PAIMENT_WARNING = "Tant qu'une commande n'est pas validée, vous pouvez revenir en arrière et modifier votre devis à volonté. Mais après validation les modifications se font manuellement avec l'agence.",
  QUOTE_WARNING = "Vérifiez bien les horaires de travail de votre équipe avant de signer le contrat. Une fois le contrat signé, vous ne pourrez plus les baisser, mais seulement les augmenter.",
}
