import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { FC, useEffect, useState } from "react";
import { useCustomerContext } from "../../context/CustomerContext";
import { CustomerModalIcon } from "../Icons/CustomerModalIcon";
import ButtonCheckbox from "../Buttons/CheckboxButton";

interface ModalProps {
  isOpen: boolean;
  onSubmit: () => void;
}

const CustomerInfoPopup: FC<ModalProps> = ({ isOpen, onSubmit }) => {
  const {
    customerInfo,
    setCompanyName,
    setEmail,
    setPronoun,
    setPhone,
    setLastName,
    setFirstName,
  } = useCustomerContext();
  const [cn, setCN] = useState(
    customerInfo?.companyName ? customerInfo.companyName : ""
  );
  const [e, setE] = useState(customerInfo?.email ? customerInfo.email : "");
  const [p, setP] = useState(customerInfo?.phone ? customerInfo.phone : "");
  const [pn, setPn] = useState(
    customerInfo?.pronoun ? customerInfo.pronoun : undefined
  );
  const [ln, setLN] = useState(
    customerInfo?.lastname ? customerInfo.lastname : ""
  );
  const [fn, setFN] = useState(
    customerInfo?.firstname ? customerInfo.firstname : ""
  );

  const [validInfo, setValidInfo] = useState(false);

  useEffect(() => {
    if (cn && e && pn) setValidInfo(true);
    else setValidInfo(false);
  }, [cn, e, pn]);

  const handleChangeCN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCN(event.target.value);
  };
  const handleChangePN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPn(event.target.value);
  };
  const handleChangeFN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFN(event.target.value);
  };

  const handleChangeLN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLN(event.target.value);
  };
  const handleChangeE = (event: React.ChangeEvent<HTMLInputElement>) => {
    setE(event.target.value);
  };

  const handleChangeP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\s/g, "")
      .replace(/(\d{2})(?=\d)/g, "$1 ");

    setP(formattedValue);
  };

  const onHandleSubmit = () => {
    if (cn) setCompanyName(cn);
    if (e) setEmail(e);
    if (pn) setPronoun(pn);
    if (p) setPhone(p);
    if (ln) setLastName(ln);
    if (fn) setFirstName(fn);

    Sentry.setUser({
      companyName: cn || "",
      email: e || "",
      pronoun: pn || "",
      phone: p || "",
      lastname: ln || "",
      firstname: fn || "",
    });

    onSubmit();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent borderRadius="12px" width="800px" maxWidth="800px">
        <ModalHeader fontSize="26">
          Nous aurons besoin de ces informations pour afficher le prix en temps
          réel
        </ModalHeader>
        <form onSubmit={onHandleSubmit}>
          <ModalBody>
            <Grid templateColumns="4fr 1fr 4fr" gap={4}>
              <Box>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    <Text display="inline">Raison Sociale</Text>
                    <Text display="inline" color="red">
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="text"
                    value={cn ? cn : ""}
                    placeholder="Renseignez votre raison sociale"
                    marginBottom="1rem"
                    onChange={handleChangeCN}
                  />
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <Flex flexDir="column">
                      <FormLabel fontWeight="bold">
                        <Text variant="xl" fontWeight="700" display="inline">
                          Quel pronom voulez-vous utiliser
                        </Text>
                        <Text display="inline" color="red">
                          *
                        </Text>
                      </FormLabel>
                      <Flex gap="2em" marginBottom="1rem">
                        <ButtonCheckbox
                          handleChange={handleChangePN}
                          active={pn === "Monsieur"}
                          value="Monsieur"
                          name="pronoun"
                          text="Monsieur"
                        />
                        <ButtonCheckbox
                          handleChange={handleChangePN}
                          active={pn === "Madame"}
                          value="Madame"
                          name="pronoun"
                          text="Madame"
                        />
                      </Flex>
                    </Flex>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    <Text display="inline">Prénom</Text>
                    <Text display="inline" color="red">
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="text"
                    value={fn ? fn : ""}
                    placeholder="Renseignez votre prénom"
                    marginBottom="1rem"
                    onChange={handleChangeFN}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    <Text display="inline">Nom</Text>
                    <Text display="inline" color="red">
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="text"
                    value={ln ? ln : ""}
                    placeholder="Renseignez votre nom"
                    marginBottom="1rem"
                    onChange={handleChangeLN}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    <Text display="inline">Email</Text>
                    <Text display="inline" color="red">
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="Email PRO (Nécessaire pour vous envoyer le devis)"
                    value={e ? e : ""}
                    placeholder="Nom@societe.com"
                    onChange={handleChangeE}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontWeight="bold">
                    <Text display="inline">Téléphone</Text>
                  </FormLabel>

                  <Input
                    type="tel"
                    maxLength={14}
                    value={p ? p : ""}
                    placeholder="06 XX XX XX XX"
                    onChange={handleChangeP}
                  />
                </FormControl>
                <FormLabel fontSize="12" marginTop="1rem">
                  <Text display="inline" color="red">
                    *Champ obligatoire
                  </Text>
                </FormLabel>
              </Box>
              <Box></Box>
              <Box m="a">
                <CustomerModalIcon width="300px" height="auto" />
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              disabled={!validInfo}
            >
              Continuer
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CustomerInfoPopup;
